import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecipeListPageComponent } from './recipe-list-page/recipe-list-page.component';
import { RecipeCreatePageComponent } from './recipe-create-page/recipe-create-page.component';
import { RecipeDetailPageComponent } from './recipe-detail-page/recipe-detail-page.component';
import { RecipeEditPageComponent } from './recipe-edit-page/recipe-edit-page.component';


const routes: Routes = [
  {path: '', component: RecipeListPageComponent},
  {path: 'create', component: RecipeEditPageComponent},
  {path: 'details/:id', component: RecipeDetailPageComponent},
  {path: 'edit/:id', component: RecipeEditPageComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
