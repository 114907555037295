<div class="content">
  <form
    *ngIf="!!dynamicForm"
    [formGroup]="dynamicForm"
    (ngSubmit)="onSubmit()"
  >
    <mat-card class="add-recipe-card">
      <mat-card-header>
        <mat-card-title>Info</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="form-row">
          <div class="form-group">
            <mat-form-field class="recipe-name">
              <input
                placeholder="Recipe Name"
                matInput
                type="text"
                formControlName="name"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group">
            <mat-form-field class="example-chip-list">
              <mat-chip-list
                #chipList
                aria-label="Tags"
              >
                <mat-chip
                  *ngFor="let tag of tags.value"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="removeTag(tag)"
                >
                  {{tag}}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Add tags - comma separated"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="addTag($event)"
                >
              </mat-chip-list>
            </mat-form-field>

          </div>
        </div>
      </mat-card-content>
      <mat-progress-bar
        *ngIf="isLoading"
        mode="indeterminate"
      ></mat-progress-bar>
    </mat-card>

    <mat-card class="add-recipe-card">
      <mat-card-header>
        <mat-card-title>Ingredients</mat-card-title>
      </mat-card-header>
      <!-- <img
            mat-card-image
            src="https://material.angular.io/assets/img/examples/shiba2.jpg"
            alt="Photo of a Shiba Inu"
          > -->
      <mat-card-content>
        <div class="form-row">
          <div
            class="form-group"
            *ngFor="let ingred of ingredients.controls; let i = index"
            [formGroup]="ingredients"
          >
            <mat-form-field>
              <input
                placeholder="Ingredient {{i + 1}}"
                matInput
                [formControlName]="i"
              >
            </mat-form-field>
            <button
              class="delete-ingredient"
              type="button"
              color="warn"
              mat-mini-fab
              (click)="ingredients.controls.length > 1 && ingredients.controls.splice(i, 1)"
            >
              <i class="material-icons">delete</i></button>
          </div>
        </div>

      </mat-card-content>
      <mat-card-actions>
        <button
          color="accent"
          class="add-ingredient"
          type="button"
          mat-raised-button
          (click)="addIngredient($event)"
        >Add Ingredient
          <i class="material-icons">add</i></button>
      </mat-card-actions>
      <mat-progress-bar
        *ngIf="isLoading"
        mode="indeterminate"
      ></mat-progress-bar>
    </mat-card>
    <mat-card class="add-recipe-card">
      <mat-card-header>
        <mat-card-title>Steps</mat-card-title>
      </mat-card-header>
      <!-- <img
            mat-card-image
            src="https://material.angular.io/assets/img/examples/shiba2.jpg"
            alt="Photo of a Shiba Inu"
          > -->
      <mat-card-content>
        <div class="form-row">
          <div
            class="form-group"
            *ngFor="let step of steps.controls as AbstractControl; let i = index;"
            [formGroup]="steps"
          >
            <!-- <mat-form-field appearance="none">
              <label color="warn">Step {{i + 1}}</label>
              <textarea
                matInput
                [formControlName]="i"
              ></textarea>
            </mat-form-field> -->
            <angular-editor
              [formControlName]="i"
              [config]="editorConfig"
            ></angular-editor>
            <button
              class="delete-step"
              type="button"
              color="warn"
              mat-mini-fab
              (click)="steps.controls.length > 1 && steps.controls.splice(i, 1)"
            >
              <i class="material-icons">delete</i></button>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button
          color="accent"
          class="add-ingredient"
          type="button"
          mat-raised-button
          (click)="addStep($event)"
        >Add Step
          <i class="material-icons">add</i></button>
      </mat-card-actions>
      <mat-progress-bar
        *ngIf="isLoading"
        mode="indeterminate"
      ></mat-progress-bar>
    </mat-card>
    <mat-card class="add-recipe-card">
      <mat-card-header>
        <mat-card-title>Notes</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="form-row">
          <div class="form-group">
            <!-- <mat-form-field appearance="none">
              <textarea
                matInput
                formControlName="notes"
                name="notes"
              ></textarea>
            </mat-form-field> -->
            <angular-editor
              formControlName="notes"
              [config]="editorConfig"
            ></angular-editor>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button
          class="cancel-button"
          mat-raised-button
          [disabled]="isSaving"
          color="warn"
          type="button"
          (click)="goBackHome()"
        >Cancel</button>
        <div class="spacer"></div>
        <button
          class="save-button"
          mat-raised-button
          color="primary"
          [disabled]="isSaving"
          type="submit"
        >Save</button>
      </mat-card-actions>
      <mat-progress-bar
        *ngIf="isLoading"
        mode="indeterminate"
      ></mat-progress-bar>
      <mat-progress-bar
        *ngIf="isSaving"
        mode="indeterminate"
      ></mat-progress-bar>
    </mat-card>
  </form>
</div>
