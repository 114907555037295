import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  HostBinding,
  Injectable,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Recipe } from '../models/models';
import { AppComponent } from '../app.component';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-recipe-edit-page',
  templateUrl: './recipe-edit-page.component.html',
  styleUrls: ['./recipe-edit-page.component.scss'],
})
export class RecipeEditPageComponent implements OnInit, AfterViewInit {
  constructor(
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {}
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '100px',
    maxHeight: 'auto',
    width: '100%',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: 'Indie Flower',
    defaultFontSize: '5',
    fonts: [
      { class: 'Indie Flower', name: 'Indie Flower' },
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [],
  };
  get f() {
    return this.dynamicForm.controls;
  }
  get ingredients() {
    return this.f.ingredients as FormArray;
  }
  get steps() {
    return this.f.steps as FormArray;
  }

  get tags() {
    return this.f.tags as FormArray;
  }

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  dynamicForm: FormGroup;
  submitted = false;

  isSaving = false;
  isLoading = false;

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.tags.push(new FormControl(value.trim()));
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    console.log(this.tags);
  }

  removeTag(index: number): void {
    this.tags.removeAt(index);
  }
  ngOnInit(): void {
    this.dynamicForm = this.formBuilder.group({
      name: ['', Validators.required],
      ingredients: new FormArray([]),
      steps: new FormArray([]),
      tags: new FormArray([]),
      notes: [''],
      id: [''],
    });
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.isLoading = true;
      this.http.get('/api/Recipes/' + id).subscribe((data: Recipe) => {
        this.dynamicForm.patchValue(data);
        data.tags.map((tag) => this.tags.push(new FormControl(tag)));
        data.steps.map((step) => this.steps.push(new FormControl(step)));
        data.ingredients.map((ingredient) =>
          this.ingredients.push(new FormControl(ingredient))
        );
        this.cdRef.detectChanges();
        this.isLoading = false;
      });
    } else {
      this.addIngredient(null);
      this.addStep(null);
    }
  }
  ngAfterViewInit() {

  }
  allIngredientsValid() {
    return this.ingredients.controls.every((v) => v.valid);
  }

  addIngredient(e) {
    this.ingredients.push(this.formBuilder.control(''));
    this.cdRef.detectChanges();
  }

  addStep(e) {
    this.steps.push(this.formBuilder.control(''));
    this.cdRef.detectChanges();
  }

  goBackHome() {
    this.router.navigate(['/']);
  }

  async onSubmit() {
    this.submitted = true;
    const recipe: Recipe = {
      ...this.dynamicForm.value,
      author: (window as any).userEmail,
      dateCreated: new Date(),
    };
    if (this.dynamicForm.invalid) {
      alert('fail!!');
      return;
    }
    // // tslint:disable-next-line:no-debugger
    // debugger;
    // console.log(recipe);
    // return;
    this.isSaving = true;
    let resp = null;
    if (this.dynamicForm.controls.id.value !== '') {
      resp = this.http.put('/api/Recipes/' + recipe.id, recipe);
    } else {
      resp = await this.http.post('/api/Recipes/', recipe);
      // .subscribe((data: Recipe) => {
      //   this.dynamicForm.controls.id.setValue(data.id);
      //   this.isSaving = false;
      //   this.snackBar.open(
      //     this.dynamicForm.controls.name.value + ' saved successfully!',
      //     null,
      //     { duration: 4000 }
      //   );
      //   this.router.navigate(['details', data.id]);
      // });
    }
    resp.subscribe((data: Recipe) => {
      if (data && data.id) {
        recipe.id = data.id;
      }
      this.snackBar.open(
        this.dynamicForm.controls.name.value + ' saved successfully!',
        null,
        { duration: 4000 }
      );
      setTimeout(() => {
        this.isSaving = false;
        this.router.navigate(['details', recipe.id]);
      }, 50);
    });
  }
}
