import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Recipe } from '../models/models';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-recipe-list-page',
  templateUrl: './recipe-list-page.component.html',
  styleUrls: ['./recipe-list-page.component.scss'],
})
export class RecipeListPageComponent implements OnInit {
  recentRecipes: Recipe[] = [];
  searchResults: Recipe[] = [];
  mostCookedRecipes: Recipe[] = [];
  searchForm: FormGroup;
  searchTerm: string;
  submitted = false;
  constructor(
    private cdRef: ChangeDetectorRef,
    private http: HttpClient,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}
  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      term: ['', Validators.required],
    });
  }
  get term() {
    return this.searchForm.get('term').value;
  }
  search() {
    this.submitted = true;
    this.http
      .get(`/api/Recipes/search/${this.term}`)
      .subscribe((data: Recipe[]) => {
        this.searchResults = data;
        this.submitted = false;
      });
  }
}
