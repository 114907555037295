<div class="content">

  <form
    [formGroup]="dynamicForm"
    (ngSubmit)="onSubmit()"
  >
    <mat-card class="add-recipe-card">
      <mat-card-header>
        <mat-card-title>Info</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="form-row">
          <div class="form-group">
            <mat-form-field class="recipe-name">
              <input
                placeholder="Recipe Name"
                matInput
                type="text"
                formControlName="name"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group">
            <mat-form-field class="example-chip-list">
              <mat-chip-list
                #chipList
                aria-label="Tags"
              >
                <mat-chip
                  *ngFor="let tag of tags.value; let i = index"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="removeTag(i)"
                >
                  {{tag}}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Add tags - comma separated"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="addTag($event)"
                >
              </mat-chip-list>
            </mat-form-field>

          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="add-recipe-card">
      <mat-card-header>
        <mat-card-title><span>Ingredients</span>
          <div class="spacer"></div>
          <button
            color="accent"
            class="add-ingredient"
            type="button"
            mat-raised-button
            (click)="addIngredient($event)"
          >Add Ingredient
            <i class="material-icons">add</i></button>

        </mat-card-title>
      </mat-card-header>
      <!-- <img
            mat-card-image
            src="https://material.angular.io/assets/img/examples/shiba2.jpg"
            alt="Photo of a Shiba Inu"
          > -->
      <mat-card-content>
        <div class="form-row">
          <div
            class="form-group"
            *ngFor="let ingred of ingredientList.controls; let i = index"
            [formGroup]="ingredientList"
          >
            <!-- <angular-editor
              [formControlName]="i"
              [config]="editorConfig"
            ></angular-editor> -->
            <mat-form-field>
              <input
                placeholder="Ingredient {{i + 1}}"
                matInput
                [formControlName]="i"
              >
            </mat-form-field>
            <button
              class="delete-ingredient"
              type="button"
              color="warn"
              mat-mini-fab
              (click)="ingredientList.controls.length > 1 && ingredientList.controls.splice(i, 1)"
            >
              <i class="material-icons">delete</i></button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="add-recipe-card">
      <mat-card-header>
        <mat-card-title><span>Steps</span>
          <div class="spacer"></div>
          <button
            color="accent"
            class="add-ingredient"
            type="button"
            mat-raised-button
            (click)="addStep($event)"
          >Add Step
            <i class="material-icons">add</i></button>
        </mat-card-title>
      </mat-card-header>
      <!-- <img
            mat-card-image
            src="https://material.angular.io/assets/img/examples/shiba2.jpg"
            alt="Photo of a Shiba Inu"
          > -->
      <mat-card-content>
        <div class="form-row">
          <div
            class="form-group"
            *ngFor="let step of stepList.controls as AbstractControl; let i = index;"
            [formGroup]="stepList"
          >
            <!-- <mat-form-field appearance="none">
              <label color="warn">Step {{i + 1}}</label>
              <textarea
                matInput
                [formControlName]="i"
              ></textarea>
            </mat-form-field> -->
            <angular-editor
              [formControlName]="i"
              [config]="editorConfig"
            ></angular-editor>
            <button
              class="delete-step"
              type="button"
              color="warn"
              mat-mini-fab
              (click)="stepList.controls.length > 1 && stepList.controls.splice(i, 1)"
            >
              <i class="material-icons">delete</i></button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="add-recipe-card">
      <mat-card-header>
        <mat-card-title>Notes</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="form-row">
          <div class="form-group">
            <!-- <mat-form-field appearance="none"> -->
            <!-- <textarea
                matInput
                formControlName="notes"
                name="notes"
              ></textarea> -->
            <angular-editor
              formControlName="notes"
              [config]="editorConfig"
            ></angular-editor>
            <!-- </mat-form-field> -->
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button
          class="cancel-button"
          mat-raised-button
          color="warn"
        >Cancel</button>
        <div class="spacer"></div>
        <button
          class="save-button"
          mat-raised-button
          color="primary"
        >Save</button>
      </mat-card-actions>
    </mat-card>

  </form>
  <div
    class="overlay"
    *ngIf="isSaving"
  ></div>
</div>
