<mat-toolbar color="primary">
  <a
    class="title"
    routerLink="/"
  >
    <mat-icon>list_alt</mat-icon>Momma's Meals
  </a>
  <span class="fill-remaining"></span>
  <div
    *ngIf="!userId"
    class="g-signin2"
    data-onsuccess="onSignIn"
  ></div>

  <mat-menu #appMenu="matMenu">
    <button mat-menu-item>Settings</button>
    <button mat-menu-item>Help</button>
  </mat-menu>

  <a
    class="menu"
    [matMenuTriggerFor]="appMenu"
  >
    <span *ngIf="userId">{{fName}}</span>
    <img
      *ngIf="imageUrl"
      src="{{imageUrl}}"
    />
  </a>

</mat-toolbar>
<!-- <mat-sidenav-container class="page-container">
  <mat-sidenav
    mode="side"
    opened
  >

    <mat-list>
      <a
        mat-list-item
        *ngFor="let navItem of navigationItems"
        routerLink="{{navItem.url}}"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }"
      >{{navItem.label}}</a>
    </mat-list>
  </mat-sidenav>
  <mat-sidenav-content> -->
<div class="page-container">
  <router-outlet *ngIf="userId"></router-outlet>
</div>
<!-- </mat-sidenav-content>
</mat-sidenav-container> -->
