import { Component, OnInit, HostListener } from '@angular/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'damber-co-app';
  fName = '';
  lName = '';
  email = null;
  imageUrl = null;
  userId = null;
  navigationItems = [
    { url: '', label: 'Search' },
    { url: 'create', label: 'Add New Recipe' },
  ];
  constructor() {}
  @HostListener('window:gProfileLoaded', ['$event'])
  updateNodes(event: CustomEvent) {
    // console.log(event.detail.profile);
    const gProfile = event.detail.profile;
    this.fName = gProfile.getName().split(' ')[0] ?? 'Anony';
    this.lName = gProfile.getName().split(' ')[1] ?? 'Mouse';
    this.userId = gProfile.getId();
    this.imageUrl = gProfile.getImageUrl();
    this.email = gProfile.getEmail();
    (window as any).userEmail = this.email;
    // console.log('ID: ' + event.detail.profile.getId()); // Do not send to your backend! Use an ID token instead.
    // console.log('Name: ' + gProfile.getName());
    // console.log('Image URL: ' + gProfile.getImageUrl());
    // console.log('Email: ' + gProfile.getEmail()); // This is null if the 'email' scope is not present.
  }
  ngOnInit() {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/platform.js';
    document.head.appendChild(script);
  }
}
