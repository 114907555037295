import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  HostBinding,
  Injectable,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Recipe } from '../models/models';
import { AppComponent } from '../app.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-recipe-create-page',
  templateUrl: './recipe-create-page.component.html',
  styleUrls: ['./recipe-create-page.component.scss'],
})
@Injectable()
export class RecipeCreatePageComponent implements OnInit, AfterViewInit {
  constructor(
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private http: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '100px',
    maxHeight: 'auto',
    width: '100%',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: 'Indie Flower',
    defaultFontSize: '5',
    fonts: [
      { class: 'Indie Flower', name: 'Indie Flower' },
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
  };
  get f() {
    return this.dynamicForm.controls;
  }

  get ingredientList() {
    return this.f.ingredients as FormArray;
  }

  get stepList() {
    return this.f.steps as FormArray;
  }

  get tags() {
    return this.f.tags as FormArray;
  }

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  dynamicForm: FormGroup;
  submitted = false;

  isSaving = false;

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.tags.push(new FormControl(value.trim()));
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    console.log(this.tags);
  }

  removeTag(index: number): void {
    this.tags.removeAt(index);
  }
  ngOnInit(): void {
    this.dynamicForm = this.formBuilder.group({
      name: ['', Validators.required],
      ingredients: new FormArray([]),
      steps: new FormArray([]),
      tags: new FormArray([]),
      notes: [''],
      id: [''],
    });
  }
  ngAfterViewInit() {
    this.addIngredient(null);
    this.addStep(null);
  }
  allIngredientsValid() {
    return this.ingredientList.controls.every((v) => v.valid);
  }

  addIngredient(e) {
    this.ingredientList.push(this.formBuilder.control(''));
    this.cdRef.detectChanges();
  }

  addStep(e) {
    this.stepList.push(this.formBuilder.control(''));
    this.cdRef.detectChanges();
  }

  async onSubmit() {
    this.submitted = true;
    const recipe: Recipe = {
      ...this.dynamicForm.value,
      author: (window as any).userEmail,
      dateCreated: new Date(),
    };
    if (this.dynamicForm.invalid) {
      alert('fail!!');
      return;
    }
    // // tslint:disable-next-line:no-debugger
    // debugger;
    // console.log(recipe);
    // return;
    this.isSaving = true;
    await this.http.post('/api/Recipes/', recipe).subscribe((data: Recipe) => {
      this.dynamicForm.controls.id.setValue(data.id);
      this.isSaving = false;
      this.snackBar.open(
        this.dynamicForm.controls.name.value + ' saved successfully!',
        null,
        { duration: 4000 }
      );
      this.router.navigate(['details', data.id]);
    });
  }

  onReset() {
    this.submitted = false;
    this.dynamicForm.reset();
    this.ingredientList.clear();
  }

  onClear() {
    this.submitted = false;
    this.ingredientList.reset();
  }
}
