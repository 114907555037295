import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  HostBinding,
  Injectable,
} from '@angular/core';

import { MatChipInputEvent } from '@angular/material/chips';
import {Router, ActivatedRoute, ParamMap } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { Recipe } from '../models/models';

@Component({
  selector: 'app-recipe-detail-page',
  templateUrl: './recipe-detail-page.component.html',
  styleUrls: ['./recipe-detail-page.component.scss']
})
export class RecipeDetailPageComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute
  ) { }
  recipe: Recipe = null;
  async ngOnInit(): Promise<void> {

    const id = this.route.snapshot.paramMap.get('id');

    this.http.get('/api/Recipes/' + id).subscribe((data: Recipe) => {
        this.recipe = data;
      });
  }

}
