<div class="content">
  <div
    class="wrapper"
    *ngIf="recipe !== null"
  >
    <mat-card class="add-recipe-card">
      <mat-card-header>
        <mat-card-title>{{recipe.name}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <!-- <h1>{{recipe.name}}</h1> -->
        <h2>Tags:</h2>
        <mat-chip-list>
          <mat-chip *ngFor="let tag of recipe.tags; let i = index">{{tag}}</mat-chip>
        </mat-chip-list>
        <!-- <ul>
          <li *ngFor="let tag of recipe.tags">{{tag}}</li>
        </ul> -->
        <mat-divider></mat-divider>
        <h2>Ingredients</h2>
        <ul>
          <li *ngFor="let ingredient of recipe.ingredients">
            <p [innerHTML]="ingredient"></p>
          </li>
        </ul>
        <mat-divider></mat-divider>
        <h2>Steps</h2>
        <div *ngFor="let step of recipe.steps; let i = index">
          <h3>Step {{i + 1}}</h3>
          <p [innerHTML]="step"></p>
        </div>
        <mat-divider></mat-divider>
        <h2>Notes</h2>
        <div [innerHTML]="recipe.notes"></div>
      </mat-card-content>
      <mat-card-actions>
        <div class="spacer"></div>
        <button
          class="save-button"
          mat-raised-button
          color="accent"
          [routerLink]="['/edit', recipe.id]"
        >Edit Recipe</button>
      </mat-card-actions>
    </mat-card>
    <!--
    <mat-card class="add-recipe-card">
      <mat-card-header>
        <mat-card-title><span>Ingredients</span></mat-card-title>
      </mat-card-header>

      <mat-card-content>

      </mat-card-content>
    </mat-card> -->
    <!-- <mat-card class="add-recipe-card">
      <mat-card-header>
        <mat-card-title><span>Steps</span></mat-card-title>
      </mat-card-header>
      <mat-card-content>

        <div *ngFor="let step of recipe.steps; let i = index">
          <h1>Step {{i + 1}}</h1>
          <p [innerHTML]="step"></p>
        </div>
      </mat-card-content>
    </mat-card> -->
    <!-- <mat-card class="add-recipe-card">
      <mat-card-header>
        <mat-card-title>Notes</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div [innerHTML]="recipe.notes"></div>
      </mat-card-content>
      <mat-card-actions>
        <div class="spacer"></div>
        <button
          class="save-button"
          mat-raised-button
          color="accent"
          [routerLink]="['/edit', recipe.id]"
        >Edit Recipe</button>
      </mat-card-actions>
    </mat-card> -->
  </div>
</div>
