<div class="wrapper">
  <form
    [formGroup]="searchForm"
    (ngSubmit)="search()"
  >
    <div class="search">
      <mat-form-field class="search-form-field">
        <mat-label>Search</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="searchTerm"
          autofocus
          formControlName="term"
        >
        <button
          (click)="searchTerm= ''"
          mat-button
          matSuffix
          mat-icon-button
          *ngIf="searchTerm"
          aria-label="Clear"
          type="button"
        >
          <mat-icon>close</mat-icon>
        </button>
        <button
          mat-raised-button
          matSuffix
          color="primary"
          (click)="search()"
        >Search</button>
      </mat-form-field>

      <span>or</span>
      <button
        routerLink="create"
        mat-raised-button
        color="accent"
      >
        <mat-icon>add</mat-icon>Add new
      </button>
    </div>
  </form>
  <div class="page-content">
    <!-- <mat-card
      class="recipe-list-card"
      *ngIf="!submitted &&  searchResults && searchResults.length > 0"
    > -->
    <!-- <h1>Search results</h1> -->
    <div
      class="recipe-list"
      *ngIf="!submitted &&  searchResults && searchResults.length > 0"
    >
      <mat-card
        class="example-card"
        *ngFor="let item of searchResults"
      >
        <mat-card-header>
          <!-- <div
              mat-card-avatar
              class="example-header-image"
            ></div> -->
          <mat-card-title>{{item.name}}</mat-card-title>
          <mat-card-subtitle>{{item.ingredients.length}} Ingredients</mat-card-subtitle>
        </mat-card-header>
        <!-- <img
            mat-card-image
            src="https://material.angular.io/assets/img/examples/shiba2.jpg"
            alt="Photo of a Shiba Inu"
          > -->
        <mat-card-content>
          <h2 *ngIf="item.notes.length > 0">Notes:</h2>
          <div [innerHTML]="item.notes ? item.notes : 'No notes available'"></div>
        </mat-card-content>
        <mat-card-actions>
          <button
          mat-raised-button
          color="accent"
          [routerLink]="'edit/' + item.id"
          >Edit</button>
          <button
            mat-raised-button
            color="primary"
            [routerLink]="'details/' + item.id"
          >View</button>
        </mat-card-actions>
      </mat-card>
    </div>
    <!-- </mat-card> -->
    <h1 *ngIf="!submitted && (!searchResults || (searchResults && searchResults.length == 0))">No search results</h1>
    <h1 *ngIf="submitted">Searching...</h1>
  </div>
</div>
